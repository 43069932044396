import { useState, useEffect } from "react";

import LoadingSpinner from "./components/LoadingSpinner";
import { CodatLink } from "./components/CodatLink";

import { config as connectedConfig } from "./components/connectedConfig";
import { config as invoiceFinanceConfig } from "./components/invoiceFinanceConfig";

//import lloyds from './components/lloyds.svg'
import lloyds from './components/lloyds.png'

import "./App.css";

function App() {
  const [companyId, setCompanyId] = useState("");
  const [productName, setProductName] = useState("connected");
  const [modalOpen, setModalOpen] = useState(false);

  const lloydsHome = "https://www.lloydsbank.com/business/home.html"

  const onConnection = (connection) =>
    console.log(`Connection | Company: ${companyId} | Connection: ${connection.connectionId}`);
  
  const onFinish = () => {
    console.log(`Success | Company: ${companyId}`);

    window.location.href = lloydsHome;
  };

  const onError = (error) => {
    console.log(`Connection | Company: ${companyId} | Error: ${error.message}`);
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const companyId = queryParams.get('company');
    const productName = queryParams.get('product');

    if (companyId) setCompanyId(companyId)
    if (productName) setProductName(productName)

    console.log(`Start | Company: ${companyId}`);

    setTimeout(() => {
      if(!!companyId) setModalOpen(true)
    }, 1000)
  }, []);


  const productOptions = {
    connected: connectedConfig,
    invoiceFinance: invoiceFinanceConfig,
  }

  return (
    <div className="App">
      <div className={`page ${!!modalOpen && 'modalOpen'}`}>
        <nav className="nav">
          <img src={lloyds} className="App-logo" alt="logo" />

          <a href={lloydsHome}>Business Banking</a>
        </nav>

        {
          !companyId && (
            <div className="row">
              This URL is incorrect. Check your original link.
            </div>
          )
        }

        {
          !!companyId && (
            <div className="row">
              <LoadingSpinner/>

              <div className="input-wrapper">
                <button onClick={() => setModalOpen(!modalOpen)}>
                  {modalOpen ? "Cancel" : "Try again"}
                </button>
              </div>
            </div>
          )
        }
      </div>

      {modalOpen && (
        <CodatLink
          companyId={companyId}
          onConnection={onConnection}
          onError={onError}
          onFinish={onFinish}
          options={productOptions[productName]}
        />
      )}
    </div>
  );
}

export default App;
